import React, { useEffect, useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ClientSlider from "../ClientSlider"

import ClientImgBraathens from "../../assets/images/client_logos/Braathens.png"
import ClientImgMSC from "../../assets/images/client_logos/MSC.png"
import ClientImgSAVR from "../../assets/images/client_logos/SAVR.png"
import ClientImgSylog from "../../assets/images/client_logos/Sylog.png"
import ClientImgSthlmFast from "../../assets/images/client_logos/SthlmFast.png"
import ClientImgTrustly from "../../assets/images/client_logos/Trustly.png"
import ClientImgHighlander from "../../assets/images/client_logos/Highlander.png"
import ClientImgPricer from "../../assets/images/client_logos/Pricer.png"
import ClientImgFujitsu from "../../assets/images/client_logos/Fujitsu.png"
import ClientImgHolmSecurity from "../../assets/images/client_logos/HolmSecurity.png"
import ClientImgFence from "../../assets/images/client_logos/Fence.png"
import ClientImgSence from "../../assets/images/client_logos/Sence.png"
import ClientImgEversec from "../../assets/images/client_logos/Eversec.png"

import styles from "./ClientsBlock.module.scss"
import useTranslation from "../../hooks/useTranslation"

const CLIENT_SLIDER_ITEMS = [
  ClientImgSAVR,
  ClientImgTrustly,
  ClientImgSence,
  ClientImgBraathens,
  ClientImgSthlmFast,
  ClientImgMSC,
  ClientImgHighlander,
  ClientImgPricer,
  ClientImgFujitsu,
  ClientImgHolmSecurity,
  ClientImgFence,
  ClientImgEversec,
  ClientImgSylog,
]

const Counter = ({ darkMode, title, count, secret }) => {
  const { t } = useTranslation("mainPage")

  const boxRef = useRef()

  const [value, setValue] = useState(0)
  const [oldCount, setOldCount] = useState(count)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!isActive) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }

      const handleObserve = entries => {
        const [entry] = entries
        setIsActive(entry.isIntersecting)
      }

      const boxElem = boxRef.current

      const observer = new IntersectionObserver(handleObserve, options)
      if (boxElem) {
        observer.observe(boxElem)
      }

      return () => {
        if (boxElem) {
          observer.unobserve(boxElem)
        }
      }
    }
  }, [boxRef, isActive])

  useEffect(() => {
    if (isActive) {
      if (count !== oldCount) {
        setValue(0)
        setOldCount(count)
      }

      if (value < count) {
        setTimeout(() => {
          setValue(value + 1)
        }, 50)
      }
    }
  }, [isActive, count, value, oldCount])

  let blockStyle = styles.counter
  if (darkMode) {
    blockStyle += " " + styles.counter__dark
  }

  let countStyle = styles.counter__count
  if (secret) {
    countStyle += " " + styles.counter__count__secret
  }

  return (
    <div ref={boxRef} className={blockStyle}>
      <div className={styles.counter__title}>{title}</div>
      <div className={countStyle}>{value}</div>
      <div className={styles.counter__text}>{t("clients.counterSubtitle")}</div>
    </div>
  )
}

const ClientsBlock = ({ className, darkMode }) => {
  const { t } = useTranslation("mainPage")

  const { site } = useStaticQuery(graphql`
    query ClientsBlockQuery {
      site {
        siteMetadata {
          missionsNum {
            notSecret
            secret
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      <div className={styles.mainTitle}>{t("clients.title")}</div>
      <div className={styles.countBlock}>
        <Counter
          darkMode={darkMode}
          title={t("clients.notSecretCounter")}
          count={site.siteMetadata.missionsNum.notSecret}
        />
        <Counter
          darkMode={darkMode}
          title={t("clients.secretCounter")}
          count={site.siteMetadata.missionsNum.secret}
          secret
        />
      </div>
      <div className={styles.miniTitle}>{t("clients.sliderTitle")}</div>
      <ClientSlider
        className={styles.clientSlider}
        items={CLIENT_SLIDER_ITEMS}
      />
    </div>
  )
}

export default ClientsBlock
